import {
  IProductList,
  PriceList,
  ShopPrices,
  StockList
} from './../models/product';
import axios, { AxiosRequestConfig } from 'axios';
import { DEFAULT_COUNTRY } from '../helpers/countries';
import { DEFAULT_LANG } from '../helpers/languages';
import { ApiCommonParams } from '../models/app';
import { IProduct } from '../models/product';
import { IHomeProduct } from '../models';

export const fetchShopPrices = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<ShopPrices[]> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/products/shop-prices/`;

  const config = {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token
    }
  };

  try {
    const {
      data: { products = [] }
    } = await axios.get<{ products: ShopPrices[] }>(url, config);

    return products;
  } catch (err) {
    return [];
  }
};

export const fetchPriceList = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<PriceList[]> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/products/price-list/`;

  const config = {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token
    }
  };

  try {
    const {
      data: { skus = [] }
    } = await axios.get<{ skus: PriceList[] }>(url, config);
    return skus;
  } catch (err) {
    return [];
  }
};

export const fetchStockList = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<StockList[]> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/products/stock-list/`;

  const config = {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token
    }
  };

  try {
    const {
      data: { items = [] }
    } = await axios.get<{ items: StockList[] }>(url, config);

    return items;
  } catch (err) {
    return [];
  }
};

export const fetchProducts = async ({
  lang = DEFAULT_LANG,
  country = DEFAULT_COUNTRY,
  token,
  offset = 0,
  limit = 10,
  category = '',
  color = '',
  size = '',
  sort = '',
  fit,
  temperature,
  insulationlevel,
  activityintensity,
  sockscushion,
  socksthickness,
  socksheight,
  sockscompression
}: ApiCommonParams & {
  offset: number;
  limit: number;
  category: string;
  color?: string | string[];
  size?: string | string[];
  sort?: string | string[];
  fit?: string | string[];
  temperature?: string | string[];
  insulationlevel?: string | string[];
  activityintensity?: string | string[];
  sockscushion?: string | string[];
  socksthickness?: string | string[];
  socksheight?: string | string[];
  sockscompression?: string | string[];
}): Promise<{ products: IProductList[]; count: number }> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/products/`;

  const config: AxiosRequestConfig = {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token,
      ['UYN-LIMIT']: limit,
      ['UYN-OFFSET']: offset
    },
    params: {
      category
    }
  };

  if (color) {
    config.params.color = color;
  }
  if (size) {
    config.params.size = size;
  }
  if (sort) {
    config.params.order = sort;
  }
  if (fit) {
    config.params.fit = fit;
  }
  if (temperature) {
    config.params.temperature = temperature;
  }
  if (insulationlevel) {
    config.params.insulationlevel = insulationlevel;
  }
  if (activityintensity) {
    config.params.activityintensity = activityintensity;
  }
  if (sockscushion) {
    config.params.sockscushion = sockscushion;
  }
  if (socksthickness) {
    config.params.socksthickness = socksthickness;
  }
  if (socksheight) {
    config.params.socksheight = socksheight;
  }
  if (sockscompression) {
    config.params.sockscompression = sockscompression;
  }

  try {
    const { data, headers } = await axios.get<{ products: IProductList[] }>(
      url,
      config
    );
    const { products = [] } = data;
    const count = Number(headers['uyn-count']) || 0;
    return {
      products: products || [],
      count
    };
  } catch (err) {
    return {
      products: [],
      count: 0
    };
  }
};

export const fetchProduct = async ({
  lang,
  country,
  token,
  slug
}: ApiCommonParams & { slug: string }): Promise<IProduct | null> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/product`;

  const headers: any = {
    ['UYN-LANGUAGE']: lang,
    ['UYN-COUNTRY']: country
  };
  if (token) {
    headers['UYN-BEARER'] = token;
  }
  const config = {
    headers,
    params: {
      product_slug: slug
    }
  };

  try {
    const { data } = await axios.get<{ product: IProduct }>(url, config);
    const { product } = data;
    return product;
  } catch (err) {
    console.log('product not found', slug);
    return null;
  }
};

export const fetchHomeProducts = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<IHomeProduct[]> => {
  const URL = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/layout/home-new-products`;

  try {
    const { data } = await axios.get(URL, {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    });

    return data['new-products'];
  } catch (err) {
    return [];
  }
};
